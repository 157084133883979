<template>
  <div class="x-map">
    <gmap-autocomplete @place_changed="setPlace"/>
    <gmap-map
        ref="gmapMap"
        :center="userCoordinates"
        :zoom="10"
        style="width: 640px; height: 360px;"
        @click="handleClick"
        :options="gmapOptions">
      <gmap-info-window
          v-if="marker"
          :opened="infoWindow.opened"
          :position="infoWindow.position"
          :options="infoWindow.options"
          @closeClick="infoWindow.opened = false">
        <div v-if="location" class="address">
          <div>{{ location.street }}</div>
          <div>{{ location.postalCode }} {{ location.city }}</div>
          <div>{{ infoWindow.country }}</div>
        </div>
      </gmap-info-window>
      <gmap-marker v-if="marker" :position="marker.position" :draggable="true" @dragend="handleMarkerDrag"/>
    </gmap-map>
    <HelpButton :id="id"/>
  </div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps';
import HelpButton from "@/components/basic/HelpButton.vue";

export default {
  name: 'XMap',
  components: {HelpButton},
  props: {
    id: String,
  },
  data() {
    return {
      map: null,
      userCoordinates: {
        lat: 0,
        lng: 0,
      },
      marker: null,
      gmapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
      },
      place: null,
      location: null,
      infoWindow: {
        opened: true,
        position: null,
        options: {
          pixelOffset: {
            width: 0,
            height: -40,
          },
        },
        country: '',
      },
      apiKey: 'AIzaSyCKmrnIZ_WcrXQ5IXrFwfQ0RmG7NbsGYy0',
    };
  },
  created() {
    this.$getLocation({}).then(coordinates => {
      this.userCoordinates = coordinates;
    }).catch(error => alert(error));
  },
  mounted() {
    this.$refs.gmapMap.$mapPromise.then(map => this.map = map);
  },
  computed: {
    mapCoordinates() {
      if (!this.map) {
        return {
          lat: 0,
          lng: 0,
        };
      }
      return {
        lat: this.map.getCenter().lat().toFixed(4),
        lng: this.map.getCenter().lng().toFixed(4),
      };
    },
    google: gmapApi,
  },
  methods: {
    handleClick(eventData) {
      this.infoWindow.opened = false;
      this.updateMarker(eventData.latLng.lat(), eventData.latLng.lng());
    },
    handleMarkerDrag(eventData) {
      this.infoWindow.opened = false;
      this.updateMarker(eventData.latLng.lat(), eventData.latLng.lng());
    },
    updateMarker(lat, lng) {
      this.marker = {
        position: {
          lat: lat,
          lng: lng,
        },
      };

      this.axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.apiKey}`).then((response) => {
        for (const result of response.data.results) {
          if (result.types.includes('street_address')) {
            this.updateLocation(result, result.geometry.location.lat, result.geometry.location.lng);
            break;
          }
        }
      });
    },
    setPlace(place) {
      this.place = place;

      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      this.updateLocation(place, lat, lng);

      this.marker = {
        position: {
          lat: lat,
          lng: lng,
        },
      };
    },
    updateLocation(place, lat, lng) {
      let houseNumber;
      let street;
      let postalCode;
      let city;
      let region;
      let countryShort;
      let countryLong;

      for (const addressComponent of place.address_components) {
        if (addressComponent.types.includes('street_number')) houseNumber = addressComponent.long_name;
        else if (addressComponent.types.includes('route')) street = addressComponent.long_name;
        else if (addressComponent.types.includes('postal_code')) postalCode = addressComponent.long_name;
        else if (addressComponent.types.includes('locality')) city = addressComponent.long_name;
        else if (addressComponent.types.includes('administrative_area_level_1')) region = addressComponent.short_name;
        else if (addressComponent.types.includes('country')) {
          countryShort = addressComponent.short_name;
          countryLong = addressComponent.long_name;
        }
      }

      this.location = {
        street: houseNumber ? `${street} ${houseNumber}` : street,
        postalCode: postalCode,
        city: city,
        region: region,
        country_iso: countryShort,
        latitude: lat,
        longitude: lng,
      };

      this.infoWindow.position = {
        lat: lat,
        lng: lng,
      };
      this.infoWindow.country = countryLong;

      this.infoWindow.opened = false;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.infoWindow.opened = true;
        });
      });
    },
  },
};
</script>

<style scoped>
.x-map {
  display: flex;
}
.x-map >>> .help-button {
  margin-top: 2px;
}
</style>