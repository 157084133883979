<template>
  <div>
    <XMap/>
  </div>
</template>

<script>
import XMap from '@/components/basic/XMap';
export default {
  name: 'OrganizationLocationPicker',
  components: {XMap},
  data() {
    return {
      place: null,
      apiKey: 'AIzaSyCKmrnIZ_WcrXQ5IXrFwfQ0RmG7NbsGYy0'
    }
  },
};
</script>

<style scoped>

</style>